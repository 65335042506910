import * as R from 'remeda';
import {
  DashboardAction,
  dashboardActions as actions,
  DashboardState,
} from 'store/page/dashboard';
import { getType } from 'typesafe-actions';
import { timeUtility } from 'utility';

const currentWeek = timeUtility.factory<Time.Week>({
  ...timeUtility.toTimeDate(new Date()),
  type: 'week',
});

const selectedWeek = currentWeek;

const selectedDay = R.pipe(
  timeUtility.getDaysFromWeek(selectedWeek.year, selectedWeek.week),
  R.values,
  R.first(),
);

const initialState: DashboardState = {
  selectedWeek,
  selectedDay: selectedDay!,
  kpis: [],
  pivotById: {},
};

const dashboardReducer = (
  state: DashboardState = initialState,
  action: DashboardAction,
): DashboardState => {
  switch (action.type) {
    case getType(actions.togglePivot):
      let pivotById = R.clone(state.pivotById);

      const pivotted = R.isTruthy(pivotById[action.payload.id]);
      if (pivotted) pivotById = R.omit(pivotById, [action.payload.id]);
      else pivotById[action.payload.id] = true;

      return R.set(state, 'pivotById', pivotById);

    case getType(actions.setSelectedWeek):
      const selectedWeek = action.payload;
      return R.set(state, 'selectedWeek', selectedWeek);
    case getType(actions.setSelectedDay):
      const selectedDay = action.payload;
      return R.set(state, 'selectedDay', selectedDay);

    default:
      return state;
  }
};

export default dashboardReducer;
