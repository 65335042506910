import * as R from 'remeda';
import { createSelector } from 'reselect';
import { activitySelectors } from 'store/data/activity';
import { subZoneSelectors } from 'store/data/subZone';
import { zoneSelectors } from 'store/data/zone';
import Types from 'Types';
import {
  aggregationUtility,
  kpiUtility,
  targetNewUtility,
  timeUtility,
} from 'utility';

import { onRelevantItems } from '../helpers';

export const domain = (state: Types.RootState) => state.aggregated.internal;

export const byId = createSelector(domain, (domain) => domain.byId);
export const allIds = createSelector(domain, (domain) => domain.allIds);

export const item = createSelector(
  (state: Types.RootState, ownProps: { id: string }) => ownProps.id,
  byId,
  (id, byId) => R.pipe(byId, R.prop(id)),
);

export const list = createSelector(allIds, byId, (allIds, byId) =>
  R.pipe(
    allIds,
    R.map((id) => R.pipe(byId, R.prop(id))),
    R.filter(R.isDefined),
  ),
);

export const byDate = createSelector(list, (list) =>
  aggregationUtility.groupByDate(list),
);

export const byZone = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'zoneId'),
);

export const bySubZone = createSelector(list, (list) =>
  aggregationUtility.groupBy(list, 'subZoneId'),
);

export const byActivity = createSelector(
  bySubZone,
  activitySelectors.list,
  (bySubZone, activities) =>
    R.pipe(
      activities,
      R.map((activity) => {
        if (!R.isDefined(activity.subZoneIds)) return;

        const internalIds = R.pipe(
          activity.subZoneIds,
          R.map((subZoneId) => bySubZone[subZoneId]),
          R.filter(R.isDefined),
          R.flatten(),
          R.uniq(),
        );

        return { id: activity.id, internalIds };
      }),
      R.filter(R.isDefined),
      R.mapToObj((a) => [String(a.id), a.internalIds]),
    ),
);

export const byEntity = createSelector(
  byZone,
  bySubZone,
  byActivity,
  (byZone, bySubZone, byActivity) => ({
    ...byZone,
    ...bySubZone,
    ...byActivity,
  }),
);

export const kpi = createSelector(
  (
    state: Types.RootState,
    ownProps: {
      entityIds: string[];
      dateId: string;
    },
  ) => ownProps,
  byDate,
  byEntity,
  byId,
  zoneSelectors.byId, // To get target
  subZoneSelectors.byId, // To get target
  activitySelectors.byId, // To get target
  (
    { dateId, entityIds },
    byDate,
    byEntity,
    byId,
    zoneById,
    subZoneById,
    activityById,
  ) => {
    let noReceived = 0;
    let noBacklog = 0;
    let noProcessed = 0;

    const date = timeUtility.dateFromId(dateId);
    const target = R.pipe(entityIds, R.last(), (mainEntityId) => {
      if (!R.isDefined(mainEntityId)) return null;

      let target: number | null = null;
      if (!R.isDefined(date)) return null;
      const zone = zoneById[mainEntityId];
      const subZone = subZoneById[mainEntityId];
      const activity = activityById[mainEntityId];

      if (R.isDefined(zone)) {
        target = targetNewUtility.findTarget(zone.targets, date);
      } else if (R.isDefined(subZone)) {
        target = targetNewUtility.findTarget(subZone.targets, date);
      } else if (R.isDefined(activity) && R.isDefined(activity.subZoneIds)) {
        target = R.pipe(
          activity.subZoneIds,
          R.map((subZoneId) => subZoneById[subZoneId]),
          R.filter(R.isDefined),
          R.map((subZone) =>
            targetNewUtility.findTarget(subZone.targets, date),
          ),
          R.filter(R.isDefined),
          R.reduce((accTarget, target) => accTarget + target, 0),
        );
      }
      return target;
    });

    onRelevantItems({
      byId,
      byDate,
      byEntity,
      dateId,
      itemCallback: (item) => {
        noProcessed = noProcessed + item.noProcessed;
        noBacklog = noBacklog + item.noBacklog;
        noReceived = noReceived + item.noReceived;
      },
      entityIds,
    });

    const kpiValues: KPI.InternalValues = {
      noInternalBacklog: kpiUtility.sanitize(noBacklog),
      noInternalProcessed: kpiUtility.sanitize(noProcessed),
      noInternalReceived: kpiUtility.sanitize(noReceived),
      target,
    };

    return kpiValues;
  },
);
