import * as R from 'remeda';
import {
  FlexHoursAction,
  flexHoursActions as actions,
  FlexHoursState,
} from 'store/page/flexHours';
import { getType } from 'typesafe-actions';
import { timeUtility } from 'utility';

const initialState: FlexHoursState = {
  selectedDates: [],
  chartMode: 'exploration',
  chartExploreDate: timeUtility.convertDay('year', timeUtility.getToday()),
  chartSelectedDateType: 'year',
  chartFilter: {},
  pivotById: {},
};

const flexHoursReducer = (
  state: FlexHoursState = initialState,
  action: FlexHoursAction,
): FlexHoursState => {
  switch (action.type) {
    case getType(actions.togglePivot):
      let pivotById = R.clone(state.pivotById);

      const pivotted = R.isTruthy(pivotById[action.payload.id]);
      if (pivotted) pivotById = R.omit(pivotById, [action.payload.id]);
      else pivotById[action.payload.id] = true;

      return R.set(state, 'pivotById', pivotById);

    case getType(actions.setSelectedDates):
      const selectedDates = action.payload;
      return R.set(state, 'selectedDates', selectedDates);

    case getType(actions.setChartMode):
      return R.set(state, 'chartMode', action.payload);
    case getType(actions.setChartSelectedDateType):
      return R.set(state, 'chartSelectedDateType', action.payload);
    case getType(actions.setChartExploreDate):
      return R.set(state, 'chartExploreDate', action.payload);
    case getType(actions.setChartFilter):
      return R.set(state, 'chartFilter', action.payload);
    default:
      return state;
  }
};

export default flexHoursReducer;
